import { useLoaderData, useParams, Navigate, useNavigate, useHref  } from "react-router-dom";
import logo from '../assets/images/font-logo.png';
// import card from '../assets/images/black-card.png';
// import userImg from '../assets/images/myaf.jpg';
import Platform from '../components/Platform';
import BusinessLinks from '../components/BusinessLinks';
import axios from 'axios';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';
const Profile = () => {
    let history = useHref ();
    const navigate = useNavigate();
    const user = useLoaderData();
    const [isLoading, setIsLoading] = useState(false)

    console.log(user)
    if (!user)
        navigate('/error')
    let profile = user.profiles[user.activeProfile]
    const { id } = useParams()
    const { Name } = useParams()
    let c;
    if (profile.colorTheme === '#d9d9d9') {
        c = '217, 217, 217'
    }
    else {
        c = profile.colorTheme
    }
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [notes, setNotes] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [pokeOpen, setPokeOpen] = useState(false);
    const handlePokeOpen = () => setPokeOpen(true);
    const handlePokeClose = () => setPokeOpen(false);
    console.log(user.name)
    if(user.company=== 'Mimar Blue' || user.company=== 'Mimar Red'){
        window.location.href = `https://people.mimararchitecture.com/users/id/${user._id.toString()}`;
    }
    if (user.name === 'null' || user.email === 'null') {
        console.log("ddd")
        const url = `/newUserInfo/${id}`
        return <Navigate to={url} replace={true} />
    }

    const handleContactSaveAnalytics = async () => {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('Name', Name);
        await axios.post(`/analytics/contactTap`, formData, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then(async (res) => {
                const json = res.data
                console.log(json)
            })
            .catch((err) => {
                console.log("No internet Connection")
            })

    }

    const handleShare = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        handlePokeClose()
        const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', name);
        formData.append('Number', phone);
        formData.append('Email', email);
        formData.append('Notes', notes);
        formData.append('profileName', Name);

        await axios.post(`/users/clientsContactsUpdate`, formData, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then(async (res) => {
                const json = res.data
                console.log(json)
            })

        const formData2 = new FormData();
        formData2.append('id', id);
        formData2.append('Name', Name);

        await axios.post(`/analytics/exchangeTap`, formData2, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then(async (res) => {
                const json = res.data
                console.log(json)
                setIsLoading(false)
                swal({
                    title: "Contact sent successfuly",
                    icon: "success",
                    button: "Continue Exploring",
                  });
            })
            
    }

    return (
        <div style={{ 'minHeight': '100vh', 'borderRadius': '0', "cursor": 'auto' }} className="profileContainer">
            <Modal open={pokeOpen} onClose={e => handlePokeClose(e)}>
                <Box className="exMUI">
                    <div className="exchangeFormHolder">
                        <div></div>
                        <div className="imgHolder">
                            <div className="mocketImage">
                                <img src={profile.image1} alt="Profile" />
                            </div>
                        </div>
                        <p style={{ 'textAlign': 'center', 'fontFamily': 'poppins-bold' }}>Share your info with <br /> {user.name}</p>
                        <form className="exchangeForm" onSubmit={handleShare}>
                            <input
                                type="tex"
                                placeholder="Name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Phone"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            <textarea value={notes} onChange={(e) => setNotes(e.target.value)} placeholder="Notes" rows="4" cols="30" />

                            <button type="submit"> {!isLoading&&'Share'} {isLoading&&<CircularProgress />}  </button>
                        </form>
                    </div>
                </Box>
            </Modal>
            <div className="mocketLogo">
                <img src={logo} alt="Profile Logo" />
            </div>
            <div className="mocketCover">
                <img src={profile.image2} alt="Profile Cover" />
            </div>
            <div className="imgHolder">
                <div className="mocketImage">
                    <img src={profile.image1} alt="Profile 2" />
                </div>
            </div>
            <div className='nameHolder'>
                <h1> {user.name} </h1>
                <h4> {profile.job}</h4>
            </div>
            <div className="contactButtons">
                {
                    (profile.contact_link === 'false') ? <a onClick={(event) => event.preventDefault()} style={{ 'background': `rgba(${c},1)`, 'color': 'white' }} href={profile.contact_link}> SAVE CONTACT </a> :
                        <a onClick={handleContactSaveAnalytics} style={{ 'background': `rgba(${c},1)`, 'color': 'white' }} href={profile.contact_link}> SAVE CONTACT </a>
                }
                <Button style={{ 'color': 'black', 'width': '105%', 'fontWeight': '800' }} className='taskOpenBtnEx' onClick={handlePokeOpen}>
                    <span className="exchangeButton">  EXCHANGE INFO </span>
                </Button>
            </div>
            {profile.bio.length > 3 && <div className="bio">
                <div className='bioTitle'>BIO</div>
                <p style={{ 'background': `rgba(${profile.colorTheme},.4)` }}>
                    {showMore ? profile.bio : `${profile.bio.substring(0,250)}`}
                    {profile.bio.length>250 && <span style={{'borderBottom':'1px solid','color':'darkgray'}} onClick={() => setShowMore(!showMore)} > {showMore? 'Show less':'Show more'} </span>}
                </p>
            </div>}
            <div className="platformsHolder">
                {profile.socialMedia && profile.socialMedia.map((app, index) => (
                    <Platform corp={false} idx={index} key={index} props={app} color={profile.colorTheme} />
                ))}
            </div>
            <div className="businessLinkHolder">
                {profile.businessInfo && profile.businessInfo.map((app, index) => (
                    <BusinessLinks corp={false} key={index} props={app} color={profile.colorTheme} idx={index} />
                ))}
            </div>
        </div>
    );
}
export default Profile;

export const ProfileDetailsLoader = async ({ params }) => {
    const { id } = params
    let res, json
    try {
        res = await axios.get('/profile/id/' + id, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
    }
    catch (err) {
        console.log("No internet Connection")
        return null
    }
    return res.data
}

export const OldProfileDetailsLoader = async ({ params }) => {
    const { Name } = params
    let res, json
    try {
        res = await axios.get('/profile/' + Name, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
    }
    catch (err) {
        console.log("No internet Connection")
        return null
    }
    console.log(res)
    return res.data
}